import revive_payload_client_bkbY2faaCe from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.0_ioredis@5.4.1_sass@1.75.0_typescript@5.4.5_vite@5.4.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_e9t0U2eeNX from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.0_ioredis@5.4.1_sass@1.75.0_typescript@5.4.5_vite@5.4.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DMlcqn0hqi from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.0_ioredis@5.4.1_sass@1.75.0_typescript@5.4.5_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_GPqXecYsQt from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.0_ioredis@5.4.1_sass@1.75.0_typescript@5.4.5_vite@5.4.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_kKEVKqMHHO from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.0_ioredis@5.4.1_sass@1.75.0_typescript@5.4.5_vite@5.4.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_CBT1lcFaCN from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.0_ioredis@5.4.1_sass@1.75.0_typescript@5.4.5_vite@5.4.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9bgBVBkdjD from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.0_ioredis@5.4.1_sass@1.75.0_typescript@5.4.5_vite@5.4.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/cms_public/.nuxt/components.plugin.mjs";
import prefetch_client_QivQGhRCTR from "/app/cms_public/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.0_ioredis@5.4.1_sass@1.75.0_typescript@5.4.5_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import auth_hVr6opS1aZ from "/app/cms_public/node_modules/.pnpm/@apptegy+nuxt-auth@2.1.0/node_modules/@apptegy/nuxt-auth/dist/runtime/plugins/auth.mjs";
import plugin_jp2FFjVoAs from "/app/cms_public/node_modules/.pnpm/@apptegy+nuxt-intl@2.1.0/node_modules/@apptegy/nuxt-intl/dist/runtime/plugin.mjs";
import plugin_s2gKgfjWzk from "/app/cms_public/node_modules/.pnpm/nuxt3-vuex-module@1.1.0_vue@3.5.12/node_modules/nuxt3-vuex-module/src/plugin.js";
import _00_staticFetch_T3Cfca6Cds from "/app/cms_public/plugins/00.staticFetch.js";
import _02_axios_client_LTXr1tzQVf from "/app/cms_public/plugins/02.axios.client.js";
import appsignal_client_uxZI1jvyNS from "/app/cms_public/plugins/appsignal.client.js";
import apptegyComponents_8cwWUkONTI from "/app/cms_public/plugins/apptegyComponents.js";
import click_outside_oytHm5ka8v from "/app/cms_public/plugins/click-outside.js";
import client_work_state_client_urfUnQS0xA from "/app/cms_public/plugins/client-work-state.client.js";
import google_analytics_client_vKtiHLcz65 from "/app/cms_public/plugins/google-analytics.client.js";
import intl_EbJJrNoJ81 from "/app/cms_public/plugins/intl.js";
import pagination_route_change_event_client_dYWcYIpCWk from "/app/cms_public/plugins/pagination-route-change-event.client.js";
import recaptcha_client_ePVGy12Odm from "/app/cms_public/plugins/recaptcha.client.js";
import v_calendar_client_Eahmfkhy07 from "/app/cms_public/plugins/v-calendar.client.js";
export default [
  revive_payload_client_bkbY2faaCe,
  unhead_e9t0U2eeNX,
  router_DMlcqn0hqi,
  payload_client_GPqXecYsQt,
  navigation_repaint_client_kKEVKqMHHO,
  check_outdated_build_client_CBT1lcFaCN,
  chunk_reload_client_9bgBVBkdjD,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QivQGhRCTR,
  auth_hVr6opS1aZ,
  plugin_jp2FFjVoAs,
  plugin_s2gKgfjWzk,
  _00_staticFetch_T3Cfca6Cds,
  _02_axios_client_LTXr1tzQVf,
  appsignal_client_uxZI1jvyNS,
  apptegyComponents_8cwWUkONTI,
  click_outside_oytHm5ka8v,
  client_work_state_client_urfUnQS0xA,
  google_analytics_client_vKtiHLcz65,
  intl_EbJJrNoJ81,
  pagination_route_change_event_client_dYWcYIpCWk,
  recaptcha_client_ePVGy12Odm,
  v_calendar_client_Eahmfkhy07
]