<template>
  <div class="result">
    <div class="documents-info">
      <a
        :href="`${item.link}/documents`"
        class="tag"
      >
        {{ $t('search.documents.tagName') }}
      </a>
      <div class="info-section">
        <div
          :style="{ backgroundImage: getGlobalSearchIcon(item.file_extension) }"
          class="file-type"
        />
        <div class="name">
          <a
            :href="item.url"
            target="_blank"
            >{{ item.name }}</a
          >
        </div>
        <div class="file-size">{{ $t('search.documents.fileSize') }}: {{ item.file_size }}</div>
        <div class="date">{{ $t('search.documents.dateUploaded') }}: {{ formatDate(item.created_at) }}</div>
        <a
          v-if="!isMobile"
          :href="`${item.link}${item.asset_path}`"
          class="document-path"
        >
          {{ item.asset_path }}
        </a>
        <p class="org-name">
          {{ item.secondary_organization.name }}
        </p>
        <a
          class="download-icon"
          target="_blank"
          :href="item.url"
        >
          <temple v-if="isMobile">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M3.125 14.5H12.875M8 1.5V12.0625M8 12.0625L3.125 8M8 12.0625L12.875 8"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="download-btn-text">{{ $t('search.documents.downloadDocument') }}</span>
          </temple>
          <img
            v-else
            :alt="$t('search.documents.download')"
            src="~/assets/download.svg"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getGlobalSearchIcon } from '../../helpers/document-icons';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  isMobile: Boolean,
});

const { item, isMobile } = props;

const formatDate = (date) => {
  const formattedDate = date ? new Date(date) : new Date();
  return new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(formattedDate);
};
</script>

<style lang="scss" scoped>
@import '../global/scss/mixins/_breakpoints.scss';

.result {
  .documents-info {
    .tag {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0.4px;
      background: #f3f3f3;
      color: #333333;
      text-decoration: none;
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 20px;
      width: fit-content;
      margin: 0 12px 25px 2px;
      &:focus {
        outline: #662e80 solid 2px;
      }
    }
    .info-section {
      display: grid;
      grid-template-columns: 60px 30% 40% 1fr;
      grid-template-rows: repeat(4, auto);
      margin-left: 12px;
      margin-top: 29px;
      .file-type,
      .name,
      .date,
      .file-size,
      .org-name,
      .document-path {
        display: grid;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .file-type {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: span 2;
        background-repeat: no-repeat;
        background-size: cover;
        height: 60px;
      }
      .name {
        grid-column-start: 2;
        grid-column-end: span 2;
        grid-row-start: 1;
        grid-row-end: 2;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.4px;
        margin-left: 12px;
        a {
          text-decoration: none;
          color: var(--link-color);
          &:hover {
            text-decoration: underline;
          }
          &:focus {
            outline: #662e80 solid 2px;
          }
        }
      }
      .file-size,
      .date {
        color: #747474;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.4px;
        grid-row-start: 1;
        grid-row-start: 2;
        margin-top: 16px;
      }
      .file-size {
        grid-column-start: 2;
        grid-column-end: 3;
        margin-left: 12px;
      }
      .date {
        grid-column-start: 3;
        grid-column-end: 4;
      }
      .document-path {
        grid-row-start: 3;
        grid-column-start: 1;
        grid-column-end: span 3;
        color: #747474;
        font-size: 16px;
        font-style: italic;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.4px;
        margin: 26px 0 24px 14px;
      }
      .org-name {
        grid-row-start: 4;
        grid-column-start: 1;
        grid-column-end: span 3;
        color: #2a5a5a;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.4px;
        margin-left: 14px;
      }
      .download-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-row-start: 1;
        grid-row-end: span 2;
        grid-column-start: 4;
        cursor: pointer;
        text-decoration: none;
        &:focus {
          outline: #662e80 solid 2px;
        }
      }
      .download-button {
        margin-top: 24px;
        grid-row-start: 5;
        grid-column-start: 2;
        grid-column-end: span 2;
        padding: 8px 16px;
        border-radius: 3px;
        background: #515151;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        &:focus {
          outline: #662e80 solid 2px;
        }
        .download-btn-text {
          margin-left: 8px;
          color: #fff;
          text-align: center;
          font-size: 14px;
          font-weight: 450;
          letter-spacing: 0.4px;
          line-height: 16px;
        }
      }
    }
  }
}
@include screen('xs') {
  .result .documents-info .info-section {
    grid-template-rows: repeat(5, auto);
    grid-template-columns: repeat(4, 1fr);
    gap: 0;
    .name {
      margin-left: 0;
    }
    .file-size {
      margin-top: 8px;
      grid-column-start: 2;
      grid-column-end: span 2;
      grid-row-start: 2;
      margin-left: 0;
    }
    .file-type {
      position: relative;
      left: -12px;
      top: -6px;
      grid-row-end: span 3;
      background-size: contain;
      height: auto;
    }
    .date {
      grid-column-start: 2;
      grid-column-end: span 2;
      grid-row-start: 3;
      margin-top: 4px;
    }
    .org-name {
      margin-top: 16px;
      margin-left: 0;
      grid-column-start: 1;
      grid-column-end: span 4;
    }
    .download-button {
      grid-column-start: 2;
      grid-column-end: span 2;
    }
  }
}
</style>
