<template>
  <div class="result">
    <div class="athletics-info">
      <div class="tag-and-time">
        <a
          class="tag"
          :href="`${item.link}/athletics`"
        >
          {{ $t('search.athletics.tagName') }}
        </a>
        <div class="time-ago">
          {{ differenceInTime }}
        </div>
      </div>
      <h3 class="date">
        {{ formattedDate }}
      </h3>
      <a
        v-if="item.home_score && item.away_score"
        :href="link"
        class="past-event"
      >
        <div
          v-if="item.title"
          class="title"
        >
          {{ item.title }}<span v-if="versus">,</span>
        </div>
        <span
          v-if="item.away_team"
          class="team away"
          >{{ item.away_team }}
        </span>
        <span
          v-if="item.versus && item.away_score"
          style="marginleft: 4px"
          >{{ item.away_score }}</span
        >
        <span v-if="item.versus"> {{ $t('search.athletics.at') }} </span>
        <span
          v-if="item.home_team"
          class="team home"
          >{{ item.home_team }}
        </span>
        <span
          v-if="item.versus && item.home_score"
          style="marginleft: 4px"
          >{{ item.home_score }}</span
        >
      </a>
      <a
        v-else
        :href="link"
        class="future-event"
      >
        {{ item.home_team }}
        <span>{{ $t('search.athletics.vs') }}</span>
        {{ item.away_team }}
      </a>
      <a
        :href="eventLocation"
        v-if="item.location"
        class="location"
        target="_blank"
      >
        {{ item.location }}
      </a>
      <div
        v-if="futureDate"
        class="time"
      >
        {{ dayOfTheWeek }} {{ $t('search.athletics.at') }} {{ timeOnly }}
      </div>
      <div
        v-if="futureDate && calendarButtonAvailable"
        class="calendar-wrapper"
      >
        <button
          class="calendar"
          @click="toggleCalendarOptions"
        >
          <img
            src="~/assets/calendar.svg"
            :alt="$t('search.athletics.calendar')"
          />
          <span>
            {{ $t('search.athletics.addToCalendar') }}
          </span>
        </button>
        <EventListButtons
          :show-calendar-buttons="showCalendarOptions"
          :event-data="item"
          :handle-back-button-click="handleBackButtonClick"
          :handle-blur="handleBlur"
          :color-overrides="colorOverrides"
          use-close-icon
        />
      </div>
      <p class="org-name">
        {{ item.secondary_organization.name }}
      </p>
    </div>
    <!-- <div class="school-logo">
      // holding for when this is added to the response
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import tinycolor from 'tinycolor2';
import { timeDifference, isFutureDate, dayOfWeek, monthDayYear } from '../../helpers/date-utils';
import EventListButtons from '../events/components/EventViews/List/EventListButtons.vue';

export default {
  name: 'ScoresSchedulePreview',
  components: {
    EventListButtons,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isMobile: Boolean,
  },
  data() {
    return {
      showCalendarOptions: false,
      // hiding the add to calendar button until v2 search is released
      calendarButtonAvailable: false,
    };
  },
  computed: {
    ...mapState({
      primaryColor: (state) => state.globals.info.primary_color,
    }),
    eventLocation() {
      return this.item.location ? `http://maps.google.com/?q=${this.item.location}` : '';
    },
    differenceInTime() {
      return this.item.event_date ? timeDifference(this.item.event_date) : '';
    },
    futureDate() {
      return this.item.event_date ? isFutureDate(this.item.event_date) : '';
    },
    dayOfTheWeek() {
      return this.item.event_date ? dayOfWeek(this.item.event_date) : '';
    },
    link() {
      return `${this.item.link || ''}/athletics#${this.item.id}`;
    },
    formattedDate() {
      return this.item.event_date ? monthDayYear(this.item.event_date) : '';
    },
    timeOnly() {
      const splitText = this.item.formatted_date.split('at');
      return splitText[1].trim();
    },
    colorOverrides() {
      return {
        '--button-primary-bg': tinycolor(this.primaryColor.hex).darken(10),
        '--button-primary-bg-hover': tinycolor(this.primaryColor.hex).darken(5),
        '--button-default-bg': this.primaryColor.hex,
        '--button-primary-text': '#FFFFFF',
      };
    },
  },
  methods: {
    handleBackButtonClick() {
      this.showCalendarOptions = false;
    },
    handleBlur() {
      this.showCalendarOptions = false;
    },
    toggleCalendarOptions() {
      this.showCalendarOptions = !this.showCalendarOptions;
    },
  },
};
</script>

<style lang="scss" scoped>
.result .athletics-info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .tag-and-time {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .tag {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.4px;
      background: #f3f3f3;
      text-decoration: none;
      color: #333333;
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 4px;
      border-radius: 20px;
      width: fit-content;
      margin-left: 2px;
      margin-right: 12px;
      &:focus {
        outline: #662e80 solid 2px;
      }
    }
    .time-ago {
      color: #747474;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.4px;
    }
  }
  .date,
  .title,
  .past-event,
  .future-event,
  .time,
  .calendar,
  .org-name,
  .location {
    margin-left: 12px;
    text-decoration: none;
    &.hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .date,
  .future-event,
  .past-event,
  .time,
  .location {
    color: #333;
  }
  .future-event,
  .past-event,
  .time,
  .location {
    letter-spacing: 0.4px;
  }
  .date,
  .time,
  .future-event {
    font-weight: 700;
  }
  .date {
    font-size: 24px;
    letter-spacing: 0.8px;
    margin-bottom: 12px;
  }
  .future-event,
  .past-event {
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
    color: var(--link-color);
  }
  .title {
    display: contents;
  }
  .time {
    font-size: 16px;
    margin-top: 8px;
  }
  .location {
    font-weight: 500;
    margin-top: 4px;
  }
  .org-name {
    margin-top: 24px;
  }
  .calendar-wrapper {
    position: relative;
    margin-top: 12px;
    .calendar {
      display: flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      border: var(--page-builder-color) 1px solid;
      background: none;
      width: fit-content;
      cursor: pointer;
      &:focus {
        outline: #662e80 solid 2px;
      }
      img {
        margin-right: 8px;
      }
      span {
        color: var(--page-builder-color);
        font-size: 12px;
        font-weight: 450;
        letter-spacing: 0.4px;
        text-decoration: none;
      }
    }
    .button-container.open {
      position: absolute;
      margin-left: 12px;
      border-radius: 4px;
      background: var(--page-builder-color);
      width: 136px;
      padding: 4px;
      margin-top: -20px;
      :deep(.back-calendar-button) {
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
.school-logo img {
  height: 68px;
  width: 68px;
  margin-right: 74px;
}
</style>
