import { useStore } from 'vuex';

export default defineNuxtPlugin(() => {
  if (!useRuntimeConfig().public.GTAG_ID) {
    return;
  }

  useHead({
    script: [
      {
        src: `https://www.googletagmanager.com/gtag/js?id=${useRuntimeConfig().public.GTAG_ID}`,
        async: true,
      },
    ],
  });

  const store = useStore();
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', useRuntimeConfig().public.GTAG_ID, {
    parent_org: store.state.parentOrgId,
    secondary_org: store.state.secondaryOrgId,
  });
});
