<template>
  <FocusTrap
    :initial-focus="() => $refs.search"
    active
  >
    <div
      id="search"
      @keydown.esc.exact.prevent="closeModal"
    >
      <div
        ref="modal-container"
        class="search-container"
      >
        <div class="search-header">
          <button
            ref="close-button"
            :aria-label="$t('search.closeSearchModal')"
            @click="closeModal"
            @keydown.enter.exact="closeModal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19 5L12 12M5 19L12 12M12 12L5 5M12 12L19 19"
                stroke="#333333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>

          <label
            for="search-id"
            class="search-id-label"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="search-icon"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.30884 8.01595C6.53901 8.63176 5.56251 9 4.5 9C2.01472 9 0 6.98528 0 4.5C0 2.01472 2.01472 0 4.5 0C6.98528 0 9 2.01472 9 4.5C9 5.56251 8.63176 6.53901 8.01595 7.30884L11.8536 11.1464C12.0488 11.3417 12.0488 11.6583 11.8536 11.8536C11.6583 12.0488 11.3417 12.0488 11.1464 11.8536L7.30884 8.01595ZM4.5 8.1C6.48822 8.1 8.1 6.48822 8.1 4.5C8.1 2.51178 6.48822 0.9 4.5 0.9C2.51178 0.9 0.9 2.51178 0.9 4.5C0.9 6.48822 2.51178 8.1 4.5 8.1Z"
                fill="#ADADAD"
              />
            </svg>
            <input
              id="search-id"
              ref="search"
              v-model="term"
              :placeholder="$t('search.searchSite')"
              maxlength="200"
              class="search-input"
              type="text"
              spellcheck="false"
              data-cy="search-input-field"
              @input="debouncedSearch"
            />
          </label>
          <a
            v-if="results.length > 0"
            ref="skipToSearchResults"
            href="#results"
            class="skip-to-content"
          >
            {{ $t('search.skipToResults') }}
          </a>
        </div>
        <div
          ref="filterScrollContainer"
          :class="['filter-tag-section', { 'filter-tag-section-dragging': isDragging }]"
          role="group"
          :aria-label="$t('search.filterResultsBy')"
          @mousedown="startDrag"
          @mouseup="stopDrag"
          @mouseleave="stopDrag"
          @mousemove="onDrag"
        >
          <button
            v-for="filter in orgSpecificTags"
            :key="filter.id"
            :class="{ active: isSelected(filter.id) }"
            :aria-checked="isSelected(filter.id)"
            role="checkbox"
            class="tag"
            @click="toggleFilter(filter.id)"
          >
            {{ filter.label }}
          </button>
        </div>
        <div class="extra-filter-and-sort-section">
          <div
            class="sort-dropdown"
            style="visibility: hidden"
          >
            <label
              for="dropdown"
              class="sort-dropdown-label"
            >
              <span v-if="screenSize > 599">{{ $t('search.sortBy') }}</span>
              <select
                class="dropdown"
                name="dropdown"
              >
                <option value="Upcoming">
                  {{ $t('search.sortDropdown.upcoming') }}
                </option>
                <option value="Past">
                  {{ $t('search.sortDropdown.past') }}
                </option>
              </select>
            </label>
          </div>
          <div
            v-if="!useRuntimeConfig().public.IS_INTRANET"
            class="search-current-org-section"
          >
            <label>
              <span>{{ toggleText }}</span>
              <div
                class="toggle-switch"
                :class="{ active: isChecked }"
              >
                <input
                  ref="inputCheckbox"
                  :checked="searchCurrentSiteVal"
                  class="input-switch"
                  type="checkbox"
                  role="switch"
                  @change="toggleCurrentSearch"
                  @keydown.exact.prevent.enter="toggleCurrentSearch"
                />
                <span
                  name="input-switch"
                  class="toggle-circle-switch"
                />
              </div>
            </label>
          </div>
        </div>
        <div class="results-container">
          <div
            v-if="characterLimitReached"
            class="character-limit-exceeded"
          >
            <span class="limit-message">{{ $t('search.characterLimitExceeded') }}</span>
          </div>
          <div
            v-if="searchInputEmpty"
            class="search-input-empty"
          >
            <div class="search-question-text">
              {{ $t('search.searchQuestion') }}
            </div>
            <div class="keyword-text">
              {{ $t('search.keywordsAndFilters') }}
            </div>
          </div>
          <LoadingIndicator
            v-else-if="searchInProgress"
            :size="80"
          />
          <div
            v-else-if="!searchInputEmpty && results.length === 0"
            class="no-results"
          >
            <div class="no-match">
              {{ $t('search.noMatchingResults', { searchTerm: term }) }}
            </div>

            <div class="try-again">
              {{ $t('search.tryAgain') }}
            </div>
          </div>
          <div
            v-else
            id="results"
            class="results"
          >
            <div
              class="sr-only"
              aria-live="polite"
              aria-atomic="true"
            >
              {{ searchResultsMsg }}
            </div>
            <component
              :is="getPreview(result.type)"
              v-for="(result, idx) in results"
              :key="idx"
              :item="result"
              :is-mobile="screenSize < 600"
              :search-current-site-val="searchCurrentSiteVal"
            />
          </div>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
import debounce from 'lodash/debounce';
import intersectionBy from 'lodash/intersectionBy';
import { mapState, mapGetters } from 'vuex';
import { FocusTrap } from 'focus-trap-vue';
import screenWidthTracker from '../pages/forms/components/mixins/screen-width-tracker';
import LoadingIndicator from '../global/LoadingIndicator.vue';
import EventPreview from './EventPreview.vue';
import ArticlePreview from './ArticlePreview.vue';
import LiveFeedPreview from './LiveFeedPreview.vue';
import DirectoryPreview from './DirectoryPreview.vue';
import ScoresSchedulePreview from './ScoresSchedulePreview.vue';
import CMSPagePreview from './CMSPagePreview.vue';
import DocumentsAssetPreview from './DocumentsAssetPreview.vue';
import FormPreview from './FormPreview.vue';
import { clientWorkWindowEvent } from '../../helpers/event-dispatch-helpers';

export default {
  name: 'Search',
  components: {
    FocusTrap,
    LoadingIndicator,
    EventPreview,
    ArticlePreview,
    LiveFeedPreview,
    DirectoryPreview,
    ScoresSchedulePreview,
    'cms-page-preview': CMSPagePreview,
    DocumentsAssetPreview,
    'public-file-preview': DocumentsAssetPreview,
    FormPreview,
  },
  mixins: [screenWidthTracker],
  props: {
    translations: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close-search'],
  data() {
    return {
      term: '',
      results: [],
      searchInputEmpty: true,
      searchInProgress: false,
      selectedFilters: [],
      orgSpecificOptions: [],
      orgSpecificTags: [],
      searchDebounce: debounce(() => {
        this.getSearchResults();
      }, 500),
      searchCurrentSiteVal: false,
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
    };
  },
  computed: {
    ...mapState({
      customSections: (state) => state.customSections,
      hasFormsService: (state) => state.featureFlags.forms_service_enabled,
    }),
    ...mapGetters({ headingColor: 'getThemeHeadingColor' }),
    toggleText() {
      return this.screenSize > 599 ? this.$t('search.currentSite') : this.$t('search.currentSiteMobile');
    },
    isChecked() {
      return this.searchCurrentSiteVal;
    },
    characterLimitReached() {
      return this.term?.length >= 200;
    },
    searchResultsMsg() {
      if (this.results.length > 0) {
        return this.$t('search.totalResults', { resultsTotal: this.results.length });
      }
      return this.$t('search.noMatchingResults');
    },
    filterOptions() {
      const options = [
        {
          label: 'Staff',
          type: 'directories',
          doc_type: 'directory',
          filter_type: 'staff',
          id: 1,
        },
        {
          label: 'Events',
          type: 'events',
          doc_type: 'event',
          filter_type: 'events',
          id: 2,
        },
        {
          label: 'Athletics',
          type: 'scores_schedules',
          doc_type: 'scores_schedule',
          filter_type: 'athletics',
          id: 3,
        },
        {
          label: 'News',
          type: 'articles',
          doc_type: 'article',
          filter_type: 'news',
          id: 4,
        },
        {
          label: 'Live feed',
          type: 'live_feeds',
          doc_type: 'live_feed',
          filter_type: 'live_feeds',
          id: 5,
        },
        {
          label: 'Documents',
          type: 'documents',
          doc_type: 'documents_asset',
          filter_type: 'assets',
          id: 6,
        },
        {
          label: 'Forms',
          type: 'forms',
          doc_type: 'form',
          filter_type: 'forms',
          id: 7,
        },
        {
          label: 'Pages',
          type: 'pages',
          doc_type: 'cms_page',
          filter_type: 'pages',
          id: 8,
        },
      ];
      return options;
    },
  },
  created() {
    this.orgSpecificOptions = intersectionBy(this.filterOptions, this.customSections, 'type');
    this.orgSpecificTags = [
      ...this.orgSpecificOptions,
      ...(this.hasFormsService ? [{ label: 'Forms', type: 'forms', id: 7 }] : []),
      { label: 'Pages', type: 'pages', id: 8 },
    ];
  },
  mounted() {
    clientWorkWindowEvent('search-modal-open');
  },
  beforeUnmount() {
    clientWorkWindowEvent('search-modal-close');
  },
  methods: {
    startDrag(event) {
      this.isDragging = true;
      this.startX = event.clientX;
    },
    stopDrag() {
      this.isDragging = false;
    },
    onDrag(event) {
      if (!this.isDragging) return;
      const deltaX = event.clientX - this.startX;
      this.$refs.filterScrollContainer.scrollLeft -= deltaX;
      this.startX = event.clientX;
    },
    toggleCurrentSearch() {
      this.searchCurrentSiteVal = !this.searchCurrentSiteVal;
      this.debouncedSearch();
    },
    toggleFilter(id) {
      const idx = this.selectedFilters.indexOf(id);
      if (idx === -1) {
        this.selectedFilters.push(id);
      } else {
        this.selectedFilters.splice(idx, 1);
      }
      this.getSearchResults();
    },
    isSelected(id) {
      return this.selectedFilters.includes(id);
    },
    getPreview(type) {
      return `${type.split('_').join('-')}-preview`;
    },
    debouncedSearch() {
      this.searchInProgress = true;
      this.searchDebounce();
    },
    async getSearchResults() {
      const { value: authToken } = useCookie('auth-token');
      if (this.term === '') {
        this.searchInputEmpty = true;
        this.searchInProgress = false;
        return;
      }
      this.searchInputEmpty = false;
      const selectedFilterTypes = this.filterOptions.filter((option) => this.selectedFilters.includes(option.id));
      const filterTypes = selectedFilterTypes.map((filter) => filter.filter_type);
      const { data } = await this.$store.dispatch('getSearchResults', {
        term: this.term,
        writableApi: useRuntimeConfig().public.WRITABLE_BASE_API_URL,
        searchByCurrentOrg: this.searchCurrentSiteVal || useRuntimeConfig().public.IS_INTRANET,
        selectedFilters: filterTypes,
        token: authToken,
      });
      this.results = data.results;
      this.searchInProgress = false;
    },
    closeModal() {
      this.$emit('close-search');
      const params = { ...this.$route.query };
      delete params.openSearch;
      return navigateTo({ path: this.$route.currentPath, query: params });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../global/scss/mixins/_breakpoints.scss';

#search {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(34, 34, 34, 0.95);
  z-index: 10;
  .search-container {
    width: 800px;
    height: 90vh;
    padding: 25px 48px;
    background: #fff;
    .results-container {
      .search-input-empty,
      .no-results,
      .character-limit-exceeded {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 72vh;
        .search-question-text,
        .keyword-text,
        .no-match,
        .try-again,
        .limit-message {
          color: #595959;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          letter-spacing: 0.8px;
        }
        .search-question-text,
        .no-match {
          font-size: 20px;
          margin-bottom: 5px;
        }
        .keyword-text,
        .try-again {
          font-size: 12px;
        }
      }
      .results .result {
        padding: 32px 0;
        border-bottom: 1px solid #ceecec;
      }
    }
  }
  .search-header {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px #adadad solid;
    .search-id-label {
      width: 100%;
      display: flex;
      .search-icon {
        margin: auto;
      }
    }
    button {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      &:focus {
        outline: #662e80 solid 2px;
      }
    }
  }
  .search-id-label .search-input {
    border: none;
    color: #333;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.8px;
    display: block;
    width: 100%;
    margin-left: 9px;
    &:focus {
      outline: none;
    }
  }
  .search-id-label .search-input::placeholder {
    color: #adadad;
  }
}
.filter-tag-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  background: #f9f9f9;
  border-radius: 8px;
  .tag {
    height: fit-content;
    width: fit-content;
    flex: none;
    background: #ffffff;
    color: #515151;
    font-weight: 450;
    line-height: 16px;
    letter-spacing: 0.4px;
    cursor: pointer;
    margin: 0 9px;
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 14px;
    border-radius: 16px;
    border: 1px solid #515151;
    &.active {
      background: #662e80;
      color: #ffffff;
    }
    &:focus-visible {
      outline: #662e80 solid 2px;
    }
  }
}
.extra-filter-and-sort-section {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px #ceecec solid;
  .sort-dropdown {
    display: flex;
    align-items: center;
    .sort-dropdown-label {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0.4px;
      color: #747474;
      .dropdown {
        border: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.4px;
        color: #333;
        &:focus {
          outline: #662e80 solid 2px;
        }
      }
    }
  }
  .search-current-org-section label {
    display: flex;
    align-items: center;
    span {
      color: #333;
      font-size: 14px;
      font-style: normal;
      font-weight: 450;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin-right: 8px;
    }
    .toggle-switch {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0px;
      border-radius: 12px;
      outline: none;
      min-width: 32px;
      width: 40px;
      height: 20px;
      background: #adadad;
      cursor: pointer;
      transition: all 0.2s ease;
      &.active {
        background: #662e80;
        .toggle-circle-switch {
          margin-left: calc(100% - 20px);
        }
      }
      [type='checkbox']:not(:checked),
      [type='checkbox']:checked {
        opacity: 0;
        cursor: pointer;
      }
      &:focus-within {
        border: 2px solid #662e80;
      }

      // Toggle styles
      .toggle-circle-switch {
        display: block;
        position: absolute;
        left: 2px;
        top: 2px;
        border-radius: 50%;
        transition: all 0.25s ease;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        background: #ffffff;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }
}
.results {
  height: 72vh;
  overflow-y: auto;
  overflow-x: hidden;
}

@include screen('<=sm') {
  #search {
    width: 100%;
    .search-container {
      position: absolute;
      top: 0;
      padding: 16px 24px;
      width: 90vw;
      .results-container {
        .search-input-empty,
        .no-results,
        .results {
          height: 70vh;
        }
      }
      .filter-tag-section {
        flex-wrap: wrap;
        justify-content: space-around;
        row-gap: 20px;
        padding: 20px 16px;
        .tag {
          margin: 0 1px;
        }
      }
    }
  }
}
@include screen('xs') {
  #search .search-container {
    .filter-tag-section {
      flex-wrap: nowrap;
      overflow-x: scroll;
      justify-content: left;
      scroll-snap-type: x mandatory;
      box-sizing: border-box;
      &.filter-tag-section-dragging {
        scroll-snap-type: none;
      }
      .tag {
        margin: 0 6px;
        scroll-snap-align: start;
      }
      .tag:first-child {
        scroll-margin-inline-start: 1rem;
      }
      padding: 8px 12px;
    }
  }
  #search .search-container .results-container {
    overflow-y: auto;
  }
  .result :deep() {
    .directory-info {
      flex-direction: column;
      .contact-section,
      .directory-image {
        margin-left: 0;
      }
      .contact-section {
        width: 100%;
        order: 3;
      }
      .directory-image {
        order: 1;
        border: none;
      }
      .personal-info {
        margin-top: 12px;
        order: 2;
        .org-name {
          margin: 16px 0;
        }
      }
    }
    .news-info {
      .author {
        margin-top: 8px;
      }
    }
  }
}
.results-container :deep(.loader) {
  margin-top: 37%;
}
</style>
