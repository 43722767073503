<template>
  <div
    id="view-events"
    class="view-events"
  >
    <LoadingIndicator v-if="loading" />
    <cms-events
      v-else
      :options="themeSettings"
      :content="content"
      :events-config="config"
      :component-id="componentId"
      :path-prefix="pathPrefix"
      :is-preview="isThemePreview"
      :logo="watermarkLogo"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import LoadingIndicator from '../../global/LoadingIndicator.vue';
import HomepageFetchHelper from '../../../helpers/homepage-fetcher.js';

export default {
  name: 'ViewEvents',
  components: {
    LoadingIndicator,
  },
  props: {
    containerIdx: {
      type: Number,
      default: null,
    },
    componentIdx: {
      type: Number,
      default: null,
    },
    containerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      content: [],
      config: {},
    };
  },
  computed: {
    ...mapState([
      'homepage',
      'theme',
      'pathPrefix',
      'isThemePreview',
      'featureFlags',
      'globals',
      'staticFilesConfig',
      'mediaPreviewContent',
    ]),
    ...mapGetters(['getEventsApi', 'getS3UrlForMedia', 'getGlobalThemeSettings']),
    ...mapGetters('translation', ['getComponentTranslation']),
    defaultData() {
      return this.mediaPreviewContent?.eventsPreviewContent || [];
    },
    themeSettings() {
      return {
        ...this.theme.containers[this.containerIdx].components[this.componentIdx].options,
        translations: this.getComponentTranslation('events'),
        globalThemeSettings: this.getGlobalThemeSettings,
      };
    },
    componentId() {
      if (this.isThemePreview) {
        return 'preview_id';
      }

      return `${this.containerId}_${this.componentIdx}`;
    },
    useS3ForMedia() {
      return this.featureFlags.cms_stored_data_events_enabled;
    },
    watermarkLogo() {
      return this.globals.watermark?.url || this.globals.logo.url;
    },
    hasMultipleEventsSections() {
      return this.$store.state.customSections.filter((section) => section.type === 'events').length > 1;
    },
    sectionIds() {
      return (
        this.homepage.events[this.componentId]?.selected_sections ||
        this.homepage.events.sectionIds[this.componentId] ||
        []
      );
    },
  },
  mounted() {
    this.config = {
      ...this.homepage.events,
      display_tags: this.homepage?.events?.display_tags || this.hasMultipleEventsSections,
    };
    this.getContent();
  },
  methods: {
    async getContent() {
      if (this.isThemePreview) {
        this.setupThemePreview();
        return;
      }

      if (this.useS3ForMedia) {
        this.content = await this.fetchContentFromS3AndValidate();
      } else {
        this.content = await this.fetchContentFromThrillshare();
      }
      this.loading = false;
    },
    setupThemePreview() {
      this.content = this.defaultData;
      this.loading = false;
    },
    async fetchContentFromS3AndValidate() {
      try {
        const s3UrlForContent = this.getS3UrlForMedia('events');
        const contentResponse = await this.$staticFetch.get(s3UrlForContent);

        const lastS3FileUpdate = contentResponse.data?.meta?.last_static_update;
        const lastMediaUpdate =
          this.staticFilesConfig?.last_static_update?.events ||
          this.staticFilesConfig?.homepage?.last_static_update?.events;
        const isStaticDataFresh = HomepageFetchHelper.validateLastStaticUpdate(lastS3FileUpdate, lastMediaUpdate);
        const eventsEntries = contentResponse?.data?.events || [];

        if (!isStaticDataFresh || !eventsEntries || HomepageFetchHelper.hasOutdatedEntries(eventsEntries, 'events')) {
          return await this.fetchContentFromThrillshare();
        }

        return this.filterEventsBySectionIds(eventsEntries);
      } catch (e) {
        console.error(e);
        return await this.fetchContentFromThrillshare();
      }
    },
    async fetchContentFromThrillshare() {
      try {
        const { url, params } = this.getEventsApi(this.$axios.defaults.baseURL);

        const contentResponse = await this.$axios.get(url, {
          params: {
            ...params,
            ...(this.sectionIds.length && { section_ids: this.sectionIds.join(',') }),
          },
          cache: { ignoreCache: false },
        });
        return contentResponse.data?.events || [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    filterEventsBySectionIds(events) {
      if (this.sectionIds.length) {
        return events.filter((event) => this.sectionIds.includes(event.custom_section_id));
      }
      return events;
    },
  },
};
</script>

<style scoped lang="scss">
.view-events {
  height: 100%;
}
</style>
