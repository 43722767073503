/* eslint-disable no-undef */
/* eslint-disable no-shadow, no-param-reassign */
import { EVENT_VIEWS, stripTimeZone, VIEW_TYPES } from '../../components/events/components/helpers/events.helper';
import isEmpty from 'lodash/isEmpty';
import { extractUrlParams } from '../../helpers/pagination';
import {
  getEventsUrl,
  getSelectedFilter,
  getSelectedFilterIds,
  getDayObject,
  getMonthRange,
  getDateParams,
  getEventSearchDateParams,
  getCurrentFormattedDate,
  setViewType,
} from '../../helpers/events-state.helper';

const VIEW_TYPE = Object.freeze({
  DATE: 'DATE',
  MONTH: 'MONTH',
});

const REQ_STATE = Object.freeze({
  INITIAL: 'INITIAL',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
});

export const state = () => ({
  hasSections: false,
  pageFilters: [],
  allEvents: [],
  fetchingEvents: REQ_STATE.INITIAL,
  sections: [],
  filters: [],
  selectedCalendars: [],
  selectedFilters: [],
  selectedFilter: [],
  currentView: EVENT_VIEWS.CALENDAR_VIEW.componentName,
  listViewType: 'month',
  currentDate: new Date().toLocaleDateString(),
  calendarViewType: VIEW_TYPES.MONTH,
  queryParams: {},
  searchText: null,
  lastFocusedElem: null,
  lastView: EVENT_VIEWS.CALENDAR_VIEW.componentName,
  searchTagText: null,
  selectedYearUpdated: false,
  lastActiveEndDate: null,
  totalPastEvents: 0,
  totalUpcomingEvents: 0,
});

export const getters = {
  getPageFilters(state) {
    return state.pageFilters;
  },
  getCurrentFullDate(state) {
    return new Date(state.currentDate);
  },
  getCurrentDate(state) {
    return new Date(state.currentDate);
  },
  getCurrentDay(state) {
    return new Date(state.currentDate).getDay();
  },
  getCurrentMonth(state) {
    return new Date(state.currentDate).getMonth();
  },
  getCurrentYear(state) {
    return new Date(state.currentDate).getFullYear();
  },
  isLoading(state) {
    return [REQ_STATE.INITIAL, REQ_STATE.PENDING].includes(state.fetchingEvents);
  },
};

export const mutations = {
  setHasSections(state, hasSections) {
    state.hasSections = hasSections;
  },
  setPageFilters(state, filters) {
    state.pageFilters = filters || [];
  },
  changeEventView(state, viewName) {
    if (!EVENT_VIEWS[viewName]) {
      console.error(`${viewName} is not a valid Event View Name`);
      return;
    }

    state.currentView = EVENT_VIEWS[viewName].componentName;
  },
  setAllEvents(state, events) {
    state.allEvents = events;
  },
  setReqStatus(state, reqStatus) {
    if (!Object.values(REQ_STATE).includes(reqStatus)) {
      console.error('Invalid Request Status: ', reqStatus);
    }
    state.fetchingEvents = reqStatus;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setSections(state, sections) {
    state.sections = sections;
  },
  setSelectedFilters(state, { checked, value }) {
    if (checked) {
      state.selectedFilters = [...state.selectedFilters, value];
      return;
    }
    state.selectedFilters = state.selectedFilters.filter((id) => id !== value);
  },
  setSelectedCalendars(state, { checked, value }) {
    if (checked) {
      state.selectedCalendars = [...state.selectedCalendars, value];
      return;
    }
    state.selectedCalendars = state.selectedCalendars.filter((id) => id !== value);
  },
  initSelectedCalendars(state, selectedCalendarsIds) {
    state.selectedCalendars = [...state.selectedCalendars, ...selectedCalendarsIds];
  },
  initSelectedFilters(state, selectedFiltersIds) {
    state.selectedFilters = [...state.selectedFilters, ...selectedFiltersIds];
  },
  setCurrentDate(state, date) {
    const currentDate = new Date(Date.parse(stripTimeZone(state.currentDate)));
    const { year, month, day } = {
      year: date.year !== undefined ? date.year : currentDate.getFullYear(),
      month: date.month !== undefined ? date.month : currentDate.getMonth(),
      day: date.day !== undefined ? date.day : 1,
    };
    state.currentDate = new Date(year, month, day).toLocaleDateString('en-US');
  },
  setSelectedYearUpdated(state, value) {
    state.selectedYearUpdated = value;
  },
  setCalViewType(state, view = VIEW_TYPE.MONTH) {
    state.calendarViewType = view;
  },
  setListViewType(state, view) {
    state.listViewType = view;
  },
  setQueryParams(state, newQueryParams) {
    state.queryParams = { ...state.queryParams, ...newQueryParams };
    if (newQueryParams.id === null) {
      delete state.queryParams.id;
    }
    if (newQueryParams.filter_ids === '') {
      delete state.queryParams.filter_ids;
    }
    if (newQueryParams.section_ids === '') {
      delete state.queryParams.section_ids;
    }
  },
  setSearchText(state, searchText) {
    state.searchText = searchText;
  },
  setLastFocusedElem(state, payload) {
    state.lastFocusedElem = payload;
  },
  setLastView(state, payload) {
    state.lastView = payload;
  },
  setEventSearchTagText(state, text) {
    state.searchTagText = text;
  },
  setLastActiveEndDate(state, date) {
    state.lastActiveEndDate = date;
  },
  setTotalPastEvents(state, total) {
    state.totalPastEvents = total;
  },
  setTotalUpcomingEvents(state, total) {
    state.totalUpcomingEvents = total;
  },
};

export const actions = {
  updateDateFilters: async ({ commit, getters }, { year, month, day, singleDay, target }) => {
    commit('setCurrentDate', { year, month, day });
    commit('setLastFocusedElem', target);
    let dateParams;
    if (singleDay) {
      dateParams = getDayObject(
        year || getters.getCurrentYear,
        month || getters.getCurrentMonth,
        day || getters.getCurrentDay,
      );
    } else {
      dateParams = getMonthRange(year || getters.getCurrentYear, month || getters.getCurrentMonth);
    }
    commit('setQueryParams', dateParams);
  },
  updateSearchText: async ({ state, commit }, search) => {
    commit('setLastView', state.currentView === 'EventsCalendarView' ? 'CALENDAR_VIEW' : 'LIST_VIEW');
    commit('setSearchText', search);
    commit('setQueryParams', { search });
    if (search) {
      commit('changeEventView', 'SEARCH_VIEW');
    } else {
      commit('changeEventView', state.lastView);
    }
  },
  updateCalendars: async ({ state, commit }, { checked, value, target }) => {
    commit('setLastFocusedElem', target);
    commit('setSelectedCalendars', { checked, value });
    commit('setQueryParams', {
      section_ids: state.selectedCalendars.toString(),
    });
  },
  updateFilters: async ({ state, commit }, filters) => {
    commit('setLastFocusedElem', filters.target);
    commit('setSelectedFilters', filters);
    commit('setQueryParams', {
      filter_ids: state.selectedFilters.toString(),
    });
  },
  filterView({ commit, rootGetters, rootState }, view) {
    const viewType = setViewType(view, rootState, rootGetters);
    switch (viewType) {
      case 'list-month':
        commit('changeEventView', 'LIST_VIEW');
        commit('setListViewType', 'month');
        break;
      case 'list-upcoming':
      case 'list-all':
        commit('changeEventView', 'LIST_VIEW');
        commit('setListViewType', 'all');
        break;
      case 'cal-month':
        commit('changeEventView', 'CALENDAR_VIEW');
        commit('setCalViewType', 'dayGridMonth');
        break;
      case 'cal-week':
        commit('changeEventView', 'CALENDAR_VIEW');
        commit('setCalViewType', 'timeGridWeek');
        break;
      case 'search-view':
        commit('changeEventView', 'SEARCH_VIEW');
        break;
      default:
        commit('changeEventView', 'LIST_VIEW');
        commit('setListViewType', 'month');
    }
  },
  setPastEvents: async ({ state, rootGetters, commit }, { params, query, useQueryCalendars, url, $axios }) => {
    const pastEvents = await $axios.get(url, {
      params: {
        ...params,
        ...getEventSearchDateParams(),
        ...query,
        ...getCurrentFormattedDate(),
        page_no: 1,
        section_ids: !useQueryCalendars ? state.sections.map((i) => i.id).join(',') : query.section_ids,
        paginate: true,
        locale: rootGetters['translation/getLocale'],
      },
    });
    commit('setTotalPastEvents', pastEvents.data.meta.links.total_entries);
    return pastEvents;
  },
  setUpcomingEvents: async ({ state, rootGetters, commit }, { params, query, useQueryCalendars, url, $axios }) => {
    const upcomingEvents = await $axios.get(url, {
      params: {
        ...params,
        ...getEventSearchDateParams(0),
        ...query,
        page_no: 1,
        section_ids: !useQueryCalendars ? state.sections.map((i) => i.id).join(',') : query.section_ids,
        paginate: true,
        locale: rootGetters['translation/getLocale'],
      },
    });
    commit('setTotalUpcomingEvents', upcomingEvents.data.meta.links.total_entries);
    return upcomingEvents;
  },
  returnSingleEvent: async ({ dispatch, commit }, { url, query, $axios }) => {
    const responseSingleEvent = await $axios.get(`${url}/${query.id}`);
    const { event } = responseSingleEvent.data;
    const startDate = new Date(event.start_at.replace(/-/g, '/').replace(/T.+/, ''));
    commit('setEventSearchTagText', event.title);
    commit('changeEventView', 'LIST_VIEW');
    dispatch('updateDateFilters', {
      year: event.year,
      month: startDate.getMonth(),
      day: startDate.getDate(),
      singleDay: true,
    });
    return [event];
  },
  buildEventComponentData: async (
    { state, commit, dispatch, getters, rootGetters }, // Vuex Props
    { $axios }, // Passing In Current Context from the Page Component
  ) => {
    commit('setReqStatus', REQ_STATE.PENDING);
    commit('setEventSearchTagText', '');
    try {
      const query = useRoute().query;
      const slug = useRoute().params.slug;
      const eventsUrl = getEventsUrl(rootGetters.getLinks, slug);
      const { url, params } = extractUrlParams(eventsUrl, $axios.defaults.baseURL);
      const selectedFilter = getSelectedFilter(query);
      const { calendarsId, filtersId } = getSelectedFilterIds(query);
      if (calendarsId) {
        commit('setSelectedCalendars', calendarsId);
      }
      if (filtersId) {
        commit('setSelectedFilters', filtersId);
      }
      const useQueryCalendars = !(!query.section_ids && state.sections.length);
      const dateParams = getDateParams(query, getters.getCurrentYear, getters.getCurrentMonth, commit);

      const { data } = await dispatch('fetchEvents', { query, params, dateParams, useQueryCalendars, url, $axios });
      const {
        meta: { filters, sections },
      } = data;
      let { events } = data;
      // the shape of this response is a little different - which is why it's outside the fetch events function
      if (query.id) {
        events = await dispatch('returnSingleEvent', { url, query, $axios });
      }
      commit('setAllEvents', [...events]);
      commit('setPageFilters', selectedFilter);
      commit('setFilters', filters);
      commit('setSections', sections);
      commit('setHasSections', sections && sections.length > 1);
      commit('setQueryParams', {
        ...params,
        ...dateParams,
        ...query,
      });
      commit('setReqStatus', REQ_STATE.SUCCESS);
    } catch (ex) {
      console.error(ex);
      commit('setReqStatus', REQ_STATE.ERROR);
      throw createError({
        statusCode: 404,
        message: 'This page could not be found',
      });
    }
  },
  fetchEvents: async (
    { state, commit, dispatch, rootGetters },
    { query, params, dateParams, useQueryCalendars, url, $axios },
  ) => {
    const refinedQueryParams = {};
    let response = [];
    Object.keys(query).forEach((key) => {
      if (!isEmpty(query[key])) {
        refinedQueryParams[key] = query[key];
      }
    });
    try {
      if (query.search) {
        const pastEvents = await dispatch('setPastEvents', { query, params, useQueryCalendars, url, $axios });
        const upcomingEvents = await dispatch('setUpcomingEvents', { query, params, useQueryCalendars, url, $axios });
        response = pastEvents;
        response.data.events = [...pastEvents.data.events, ...upcomingEvents.data.events];
      } else if (
        (state.listViewType === 'all' && state.currentView === 'EventsListView') ||
        query.view === 'list-all'
      ) {
        response = await $axios.get(url, {
          params: {
            ...params,
            ...refinedQueryParams,
            page_no: 1,
            section_ids: !useQueryCalendars ? state.sections.map((i) => i.id).join(',') : query.section_ids,
            paginate: true,
            locale: rootGetters['translation/getLocale'],
          },
          cache: { ignoreCache: false },
        });
      } else {
        response = await $axios.get(url, {
          params: {
            ...params,
            ...dateParams,
            ...refinedQueryParams,
            section_ids: !useQueryCalendars ? state.sections.map((i) => i.id).join(',') : query.section_ids,
            paginate: false,
            locale: rootGetters['translation/getLocale'],
          },
          cache: { ignoreCache: false },
        });
      }
      return response;
    } catch (ex) {
      console.error(ex);
      commit('setReqStatus', REQ_STATE.ERROR);
      throw createError({
        statusCode: 404,
        message: 'This page could not be found',
      });
    }
  },
};
