<template>
  <div id="view-athletics">
    <LoadingIndicator v-if="loading" />
    <cms-athletics
      v-else
      :options="themeSettings"
      :content="content"
      :athletics-config="config"
      :component-id="componentId"
      :path-prefix="pathPrefix"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import LoadingIndicator from '../../global/LoadingIndicator';
import HomepageFetchHelper from '../../../helpers/homepage-fetcher.js';

export default {
  name: 'ViewAthletics',
  components: {
    LoadingIndicator,
  },
  props: {
    containerIdx: {
      type: Number,
      default: null,
    },
    componentIdx: {
      type: Number,
      default: null,
    },
    containerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      content: [],
      config: {},
    };
  },
  computed: {
    ...mapState([
      'homepage',
      'theme',
      'pathPrefix',
      'isThemePreview',
      'featureFlags',
      'staticFilesConfig',
      'mediaPreviewContent',
    ]),
    ...mapGetters(['getAthleticsApi', 'getS3UrlForMedia', 'getGlobalThemeSettings']),
    ...mapGetters('translation', ['getComponentTranslation']),
    defaultData() {
      return this.mediaPreviewContent?.athleticsPreviewContent || [];
    },
    themeSettings() {
      return {
        ...(this.theme.containers[this.containerIdx]?.components?.[this.componentIdx]?.options || {}),
        translations: this.getComponentTranslation('athletics'),
        globalThemeSettings: this.getGlobalThemeSettings,
      };
    },
    componentId() {
      if (this.isThemePreview) {
        return 'preview_id';
      }

      return `${this.containerId}_${this.componentIdx}`;
    },
    useS3ForMedia() {
      return this.featureFlags.cms_stored_data_athletics_enabled;
    },
  },
  mounted() {
    this.config = this.homepage.athletics || {};
    this.getContent();
  },
  methods: {
    async getContent() {
      if (this.isThemePreview) {
        this.setupThemePreview();
        return;
      }

      if (this.useS3ForMedia) {
        this.content = await this.fetchContentFromS3AndValidate();
      } else {
        this.content = await this.fetchContentFromThrillshare();
      }
      this.loading = false;
    },
    setupThemePreview() {
      this.content = this.defaultData;
      this.loading = false;
    },
    async fetchContentFromS3AndValidate() {
      try {
        const s3UrlForMedia = this.getS3UrlForMedia('athletics');
        const contentResponse = await this.$staticFetch.get(s3UrlForMedia);

        const lastS3FileUpdate = contentResponse.data?.meta?.last_static_update;
        const lastMediaUpdate =
          this.staticFilesConfig?.last_static_update?.athletics ||
          this.staticFilesConfig?.homepage?.last_static_update?.athletics;
        const isStaticDataFresh = HomepageFetchHelper.validateLastStaticUpdate(lastS3FileUpdate, lastMediaUpdate);
        const athleticsEvents = contentResponse?.data?.scores_schedules;

        if (
          !isStaticDataFresh ||
          !athleticsEvents ||
          HomepageFetchHelper.hasOutdatedEntries(athleticsEvents, 'athletics')
        ) {
          return await this.fetchContentFromThrillshare();
        }

        return athleticsEvents;
      } catch (e) {
        console.error(e);
        return await this.fetchContentFromThrillshare();
      }
    },
    async fetchContentFromThrillshare() {
      try {
        const { url, params } = this.getAthleticsApi(this.$axios.defaults.baseURL);
        const contentResponse = await this.$axios.get(url, { params, cache: { ignoreCache: false } });
        return contentResponse?.data?.scores_schedules || [];
      } catch (e) {
        console.error(e);
        return [];
      }
    },
  },
};
</script>

<style scoped lang="scss">
#view-athletics {
  width: 100%;
  height: 100%;
}
</style>
