<template>
  <span
    class="modal__close"
    data-testid="forms-modal-close"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 16 16"
      :class="['modal-close-icon']"
      :fill="fill"
    >
      <path
        class="modal-close-icon__path"
        d="M4.1 4.1C4.3 4 4.7 4 4.9 4.1L11.9 11.1C12 11.3 12 11.7 11.9 11.9 11.7 12 11.3 12 11.1 11.9L4.1 4.9C4 4.7 4 4.3 4.1 4.1Z"
      />
      <path
        class="modal-close-icon__path"
        d="M11.9 4.1C12 4.3 12 4.7 11.9 4.9L4.9 11.9C4.7 12 4.3 12 4.1 11.9 4 11.7 4 11.3 4.1 11.1L11.1 4.1C11.3 4 11.7 4 11.9 4.1Z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'ModalCloseIcon',
  props: {
    fill: {
      type: String,
      default: '#a4a4a4',
    },
  },
};
</script>
