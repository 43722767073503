<template>
  <LoadingIndicator v-if="isLoading" />
  <cms-media-custom-html
    v-else
    :theme="themeOptions"
    :content="content"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import LoadingIndicator from '../../../global/LoadingIndicator.vue';
import HomepageFetchHelper from '../../../../helpers/homepage-fetcher';

export default {
  name: 'ViewMediaCode',
  components: {
    LoadingIndicator,
  },
  props: {
    containerId: {
      type: String,
      default: null,
    },
    containerIdx: {
      type: Number,
      default: null,
    },
    componentIdx: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      content: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    if (this.useS3ForMedia) {
      this.content = await this.fetchEntriesFromS3();
    } else {
      this.content = await this.fetchEntriesFromThrillshare();
    }
    this.isLoading = !this.content;
  },
  computed: {
    ...mapState([
      'theme',
      'pathPrefix',
      'customSectionPaths',
      'settings',
      'featureFlags',
      'staticFilesConfig',
      'globals',
    ]),
    ...mapGetters(['getS3UrlForMedia']),
    ...mapState('translation', ['locale']),
    ...mapGetters('translation', ['getComponentTranslation']),
    themeOptions() {
      return {
        ...this.theme.containers[this.containerIdx].components[this.componentIdx].options,
        translations: this.getComponentTranslation('media_custom_code'),
      };
    },
    fallbackImage() {
      const liveFeedDefaultFallbackImage =
        this.globals?.fallback_images?.find((item) => item.feature === 'live_feed') || {};
      const newsDefaultFallbackImage = this.globals?.fallback_images?.find((item) => item.feature === 'news') || {};

      if (!this.themeOptions || !this.settings) return '';
      const newsFallbackImg =
        Object.keys(this.settings?.newsFallbackImage || {}).length === 0
          ? newsDefaultFallbackImage
          : this.settings.newsFallbackImage;
      const liveFeedFallbackImg =
        Object.keys(this.settings?.liveFeedFallbackImage || {}).length === 0
          ? liveFeedDefaultFallbackImage
          : this.settings.liveFeedFallbackImage;
      if (this.customSectionType === 'news') return newsFallbackImg;
      if (this.customSectionType === 'live_feed') return liveFeedFallbackImg;
      return '';
    },
    customSectionInfo() {
      return this.themeOptions.section;
    },
    customSectionType() {
      return this.mapSectionToPath(this.customSectionInfo.type);
    },
    sectionHasExpirableEvents() {
      const timeSensitiveCustomSections = ['athletics', 'events'];
      return timeSensitiveCustomSections.includes(this.customSectionType);
    },
    useS3ForMedia() {
      const flag = `cms_stored_data_${this.customSectionType}_enabled`;
      return this.featureFlags[flag];
    },
  },
  methods: {
    async fetchEntriesFromS3() {
      try {
        const s3UrlForContent = this.getS3UrlForMedia(this.customSectionType);
        const contentResponse = await this.$staticFetch.get(s3UrlForContent);

        const lastS3FileUpdate = contentResponse.data?.meta?.last_static_update;
        const lastMediaUpdate =
          this.staticFilesConfig?.last_static_update?.[this.customSectionInfo.api] ||
          this.staticFilesConfig?.homepage?.last_static_update?.[this.customSectionInfo.api];
        const isStaticDataFresh = HomepageFetchHelper.validateLastStaticUpdate(lastS3FileUpdate, lastMediaUpdate);

        const sectionData =
          this.customSectionInfo.type === 'events'
            ? this.filterEventsBySectionIds(contentResponse.data?.[this.customSectionInfo.api])
            : contentResponse.data?.[this.customSectionInfo.api];
        const hasOutDatedEntries = this.sectionHasExpirableEvents
          ? HomepageFetchHelper.hasOutdatedEntries(sectionData, this.customSectionType)
          : false;
        if (!isStaticDataFresh || !sectionData || hasOutDatedEntries) {
          return await this.fetchEntriesFromThrillshare();
        }
        return {
          section_name: this.customSectionInfo.name,
          entries: sectionData,
          path_prefix: this.pathPrefix,
          fallback_image: this.fallbackImage,
        };
      } catch (e) {
        console.error(e);
        return await this.fetchEntriesFromThrillshare();
      }
    },
    async fetchEntriesFromThrillshare() {
      try {
        const { customSectionURL } = this.createCustomSectionLinks(this.customSectionInfo, this.customSectionPaths);
        const sectionData = await this.$axios.get(customSectionURL, {
          params: {
            locale: this.locale,
          },
        });
        return {
          section_name: this.customSectionInfo.name,
          entries: sectionData.data[this.customSectionInfo.api],
          path_prefix: this.pathPrefix,
          fallback_image: this.fallbackImage,
        };
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    filterEventsBySectionIds(events) {
      const filterID = this.customSectionInfo.id;
      if (filterID) {
        return events.filter((event) => event.custom_section_id === filterID);
      }
      return events;
    },
    createCustomSectionLinks(customSection, paths) {
      const urlBase = `v4.${this.customSectionType}`;
      const defaultPath = `${urlBase}.main`;
      const customSectionPath =
        customSection.type === 'all_events' ? defaultPath : `${urlBase}.sections.${kebabCase(customSection.name)}`;
      const customSectionURL = get(
        paths,
        customSectionPath,
        get(paths, defaultPath, get(paths, this.customSectionType)),
      );

      return { ...customSection, customSectionURL };
    },
    mapSectionToPath(cstype) {
      switch (cstype) {
        case 'live_feeds':
          return 'live_feed';
        case 'articles':
          return 'news';
        case 'menus':
          return 'dining';
        case 'scores_schedules':
          return 'athletics';
        case 'directories':
          return 'staff';
        case 'all_events':
          return 'events';
        default:
          return cstype;
      }
    },
  },
};
</script>
