<template>
  <div class="form-group">
    <div class="form-group__helpers">
      <label
        v-if="label"
        :for="id"
        class="form-group__label"
      >
        {{ label }}
        <span
          class="form-group__required"
          aria-hidden="true"
          >*</span
        >
        <span class="sr-only">{{ $t('forms.required') }}</span></label
      >
    </div>

    <div class="form-group__feedback-wrapper">
      <div class="form-group__element">
        <component
          :is="type"
          :id="id"
          :model-value="modelValue"
          :has-errors="hasErrors"
          :placeholder="placeholder"
          :aria-describedby="`${id}-errors`"
          :autocomplete="autocomplete"
          @update:model-value="$emit('update:modelValue', $event)"
        />
      </div>

      <div
        v-if="hasErrors"
        :id="`${id}-errors`"
        class="form-group__error-message"
      >
        <p
          v-for="error in Object.keys(errors)"
          :key="error"
        >
          {{ errorMessages[error] }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from './TextInput';
import TextArea from './TextArea';

export default {
  name: 'FormGroup',
  components: {
    TextInput,
    TextArea,
  },
  props: {
    type: {
      type: String,
      default: 'TextInput',
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Default Label',
    },
    modelValue: {
      type: [String, Object],
    },
    placeholder: {
      type: String,
    },
    errors: {
      type: Object,
      default: null,
    },
    autocomplete: {
      type: String,
      default: '',
    },
  },
  computed: {
    errorMessages() {
      return {
        required: this.$t('pages.shared.fieldRequiredError'),
        email: this.$t('pages.shared.emailInvalidError'),
      };
    },
    hasErrors() {
      return !!this.errors;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../scss/color_vars';

.form-group {
  --group-label-color: #{$primary-dark};
  --group-message-color: #{$primary-dark};
  --group-error-color: #{$primary-red};
  --group-required-color: #{$primary-red};
  --group-helpers-disabled-color: #{$primary-gray};

  margin-bottom: 24px;

  &__helpers {
    position: relative;

    &--show-char-counter {
      padding-right: 50px;
    }

    &--disabled {
      .form-group__label,
      .form-group__required,
      .form-group__helper-message,
      .form-group__character-counter {
        color: var(--group-helpers-disabled-color);
      }
    }
  }

  &__label {
    margin-bottom: 10px;
    color: var(--group-label-color);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
  }

  &__error-wrapper {
    position: relative;
  }

  &__helper-message {
    margin: 4px 0 2px;
    color: var(--group-message-color);
  }

  &__error-message {
    font-weight: 450;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    position: absolute;
    margin-top: 4px;
    color: var(--group-error-color);
  }

  &__required {
    color: var(--group-required-color);
  }

  &__feedback-wrapper {
    position: relative;

    .form-group__element {
      &-icon-error {
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 1;
      }
    }
  }
}
</style>
