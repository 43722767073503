<template>
  <div
    v-if="siteIsLoadedAndEnabled"
    id="app"
    :style="`font-family: '${font}', Helvetica, Arial, sans-serif`"
  >
    <a
      ref="skipToContent"
      href="#container"
      class="skip-to-content"
    >
      {{ componentTranslations.globals.skip_to_content }}
    </a>
    <Search
      v-if="searchOpen"
      :translations="componentTranslations.globals"
      @close-search="$store.commit('setSearchOpen', false)"
    />
    <Lightbox
      v-if="showLightbox"
      :translations="componentTranslations.globals"
    />
    <div
      v-if="hasGlobalContainers"
      class="global-containers"
    >
      <ViewContainer
        v-for="container in globalContainers"
        :key="container.containerIndex"
        :container="container"
        :container-idx="container.containerIndex"
      />
    </div>
    <header id="view-header">
      <AlertContainer
        v-if="isClient"
        :hide-alerts="hideAlerts"
        @keyboard-close="focusTopElement"
      />
      <slot name="intranet"></slot>
      <cms-header
        ref="cms-header"
        :theme="headerOptions"
        :content="content"
        @language-change="languageChange"
        @toggle-mobile-menu="toggleMobileMenu"
        @toggle-menu="toggleMenu"
        @toggle-search="toggleSearch"
      />
      <nav>
        <cms-menu
          v-if="!mobileHeaderStatus"
          :theme="menuOptions"
          :content="content"
          @close-menu="$store.commit('closeMenu')"
        />
      </nav>
    </header>
    <div
      id="container"
      class="main"
      role="main"
    >
      <slot />
    </div>
    <ViewFooter />
    <StickyFooter v-if="displayStickyFooter" />
    <browser-warning
      v-if="showingBrowserNotice"
      @close="closeBrowserModal()"
    />
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapState, mapGetters, useStore } from 'vuex';
import * as cheerio from 'cheerio';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import { formatPageTitle } from '../helpers/strings';
import ViewFooter from '../components/home_page/footers/ViewFooter.vue';
import Lightbox from '../components/global/Lightbox.vue';
import Search from '../components/search/Search.vue';
import AlertContainer from '../components/home_page/alert_banner/AlertContainer.vue';
import BrowserWarning from '../components/global/BrowserWarning.vue';
import StickyFooter from '../components/home_page/sticky_footer/ViewStickyFooter.vue';
import isEmpty from 'lodash/isEmpty';
import ViewContainer from '../components/home_page/ViewContainer.vue';
import { getContrastColor } from '../helpers/colors';

export default {
  name: 'App',
  components: {
    BrowserWarning,
    Lightbox,
    Search,
    AlertContainer,
    StickyFooter,
    ViewFooter,
    ViewContainer,
  },
  props: {
    disableLoadEvents: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    let script = [];
    const styleTags = [];
    const linkTags = store.state.globals.favicon?.url
      ? [{ rel: 'icon', type: 'image/x-icon', href: store.state.globals.favicon.url }]
      : [];
    const organizationClassName = kebabCase(store.state.globals.info.school_name);

    linkTags.push(
      { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
      { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: true },
      {
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&family=Work+Sans:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,700&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;1,900&display=swap',
      },
      { rel: 'stylesheet', href: '/alias_font_faces.css' },
    );

    let noscript = [];
    if (store.state.globals.settings.embedCodes) {
      const {
        googleAnalytics,
        facebookPixel,
        googleTagManager: { head: googleTagManagerHead, body: googleTagManagerBody },
        customCodes,
      } = store.state.globals.settings.embedCodes;
      const scriptsArray = [
        { type: 'googleAnalytics', code: googleAnalytics },
        { type: 'facebookPixel', code: facebookPixel },
        { type: 'googleTagManagerHead', code: googleTagManagerHead },
        { type: 'googleTagManagerBody', code: googleTagManagerBody },
        ...customCodes.map((code) => ({ type: 'customCode', code })),
      ];
      const scriptTypesInsertedInFooter = ['googleTagManagerBody', 'customCode'];
      const scriptAttrsToMaintain = ['src', 'async', 'defer'];

      script = scriptsArray
        .filter((scriptObj) => scriptObj.code)
        .reduce((array, scriptObj) => {
          const $ = cheerio.load(scriptObj.code);
          const scripts = $('script')
            .toArray()
            .map((scriptNode) => {
              const $scriptNode = $(scriptNode);
              const currentAtts = $scriptNode.attr();
              const validAttrs = Object.keys(currentAtts).reduce((acc, key) => {
                if (scriptAttrsToMaintain.includes(key) || key.startsWith('data-')) {
                  acc[key] = currentAtts[key] === '' && !key.startsWith('data-') ? key : currentAtts[key];
                }
                return acc;
              }, {});

              return {
                ...validAttrs,
                type: 'text/javascript',
                body: scriptTypesInsertedInFooter.includes(scriptObj.id),
                innerHTML: $scriptNode.html(),
              };
            });
          return [...array, ...scripts];
        }, []);

      noscript = scriptsArray
        .filter((scriptObj) => scriptObj.code)
        .reduce((array, scriptObj) => {
          const $ = cheerio.load(scriptObj.code);
          const noScripts = $('noscript')
            .toArray()
            .map((node) => ({ innerHTML: $(node).html(), body: true }));
          return [...array, ...noScripts];
        }, []);
    }

    if (store.state.theme.template_code) {
      const externalScripts = store.state.theme.template_code.script_codes
        .filter((s) => s)
        .map((scriptUrl) => ({
          type: 'text/javascript',
          body: true,
          src: scriptUrl,
        }));
      const externalStylesheets = store.state.theme.template_code.style_codes
        .filter((s) => s)
        .map((styleUrl) => ({
          rel: 'stylesheet',
          href: styleUrl,
        }));

      script.push(...externalScripts);
      linkTags.push(...externalStylesheets);

      if (store.state.theme.template_code.css_code) {
        styleTags.push({
          type: 'text/css',
          innerHTML: store.state.theme.template_code.css_code,
        });
      }
      if (store.state.theme.template_code.js_code) {
        script.push({
          type: 'text/javascript',
          body: true,
          innerHTML: store.state.theme.template_code.js_code,
        });
      }
    }

    const globalTheme = () => {
      const fallbackColor = '#000';
      const primaryColor = get(store.state.globals, 'info.primary_color.hex', fallbackColor);
      const secondaryColor = get(store.state.globals, 'info.secondary_color.hex', fallbackColor);
      return [
        `--primary-color: ${primaryColor};`,
        ` --primary-color-contrast: ${getContrastColor(primaryColor)};`,
        `--secondary-color: ${secondaryColor};`,
        `--secondary-color-contrast: ${getContrastColor(secondaryColor)};`,
        `--link-color: ${get(store.state.globals, 'info.links_color.hex', fallbackColor)};`,
        `--links-color: ${get(store.state.globals, 'info.links_color.hex', fallbackColor)};`,
        `--heading: ${get(store.state.globals, 'info.cms_heading_color.hex', fallbackColor)};`,
        `--heading-color: ${get(store.state.globals, 'info.cms_heading_color.hex', fallbackColor)};`,
        `--page-builder-color: ${get(store.state.globals, 'info.page_builder_theme_color.hex', fallbackColor)};`,
        `--alert-text-color: ${get(store.state.globals, 'info.alert_text_color.hex', fallbackColor)};`,
        `--alert-background-color: ${get(store.state.globals, 'info.alert_bg_color.hex', fallbackColor)};`,
        `--alert-link-color: ${get(store.state.globals, 'info.alert_link_color.hex', fallbackColor)};`,
        `--alert-lightbox-shadow-color: ${get(store.state.globals, 'info.alert_lightbox_overlay.hex', fallbackColor)};`,
        `--font-family: '${store.state.font}', Helvetica, Arial, sans-serif`,
      ];
    };

    script.unshift({
      type: 'text/javascript',
      body: true,
      innerHTML: `window.clientWorkStateTemp = JSON.parse(${JSON.stringify(
        JSON.stringify(store.state),
      )}); window.clientWorkState = JSON.parse(${JSON.stringify(JSON.stringify(store.getters.getClientWorkState))})`,
    });

    const robotsIndexMeta = store.state.domain.includes('sites.thrillshare')
      ? { hid: 'robots', name: 'robots', content: 'noindex' }
      : {};
    useHead({
      htmlAttrs: {
        lang: store.state.translation.locale,
      },
      titleTemplate: (titleChunk) => {
        if (!titleChunk) {
          return store.state.globals.info.school_name;
        }
        return formatPageTitle(store.state.globals, 'info.school_name', titleChunk);
      },
      link: linkTags,
      style: styleTags,
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        { hid: 'description', name: 'description', content: store.state.globals.info.school_tag_line },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:title', property: 'og:title', content: store.state.globals.info.school_name },
        { hid: 'og:description', property: 'og:description', content: store.state.globals.info.school_tag_line },
        { hid: 'og:image', property: 'og:image', content: store.state.globals.logo.url },
        // Twitter Tags
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:title', name: 'twitter:title', content: store.state.globals.info.school_name },
        { hid: 'twitter:description', name: 'twitter:description', content: store.state.globals.info.school_tag_line },
        { hid: 'twitter:image', name: 'twitter:image', content: store.state.globals.logo.url },
        robotsIndexMeta,
      ],
      bodyAttrs: {
        class: computed(() =>
          store.state.showLightbox || store.state.menuStatus
            ? `${organizationClassName} noscroll`
            : organizationClassName,
        ),
        style: globalTheme,
      },
      script,
      noscript,
    });
  },
  data() {
    return {
      showingBrowserNotice: false,
      wasSearchModalOpenInLastUrl: false,
      mobileHeaderStatus: false,
      isClient: false,
    };
  },
  computed: {
    ...mapState([
      'globals',
      'homepage',
      'theme',
      'font',
      'domain',
      'organizations',
      'menuStatus',
      'pathPrefix',
      'isThemePreview',
      'showLightbox',
      'menu',
      'customMenuItems',
      'searchOpen',
      'configLoading',
      'featureFlags',
    ]),
    ...mapState('translation', ['language', 'languages', 'locale', 'componentTranslations']),
    ...mapGetters('translation', ['getComponentTranslation']),
    ...mapGetters(['getSearchStatus']),
    content() {
      return {
        customMenuItems: this.customMenuItems,
        globals: this.globals,
        hasCustomSchoolsMenu: this.hasCustomSchoolsDropdown,
        isIFrame: false,
        isMenuV4Enabled: this.isMenuV4Enabled,
        isPreview: this.isThemePreview,
        isSearchOpen: this.searchOpen,
        langCode: this.locale,
        langText: this.language,
        languages: this.languages,
        menuOptions: this.menuOptions,
        newMobileHeader: this.newMobileHeader,
        orgDropdownTitle: this.orgDropdownTitle,
        orgMenuContent: this.orgMenuContent,
        organizations: this.organizations,
        pathPrefix: this.pathPrefix,
        sitemapMenuItems: this.menu,
        showMenu: this.menuStatus,
        translations: this.translations,
        utilities: this.utilities,
      };
    },
    headerOptions() {
      return { ...this.theme.header.options, translations: { ...this.getHeaderValues } };
    },
    menuOptions() {
      return {
        ...this.theme.menu.options,
        translations: this.getComponentTranslation('menu'),
      };
    },
    siteIsLoadedAndEnabled() {
      const isDisabledOnCMSPublic = this.featureFlags.disable_cms_public;
      return !this.configLoading && (!isDisabledOnCMSPublic || this.isThemePreview);
    },
    isMenuV4Enabled() {
      return this.theme?.menu?.options?.version === 'M4';
    },
    utilities() {
      return this.homepage?.header?.links || [];
    },
    translations() {
      return { ...this.componentTranslations.globals, ...this.theme.translations };
    },
    getHeaderValues() {
      const headerValues = { ...this.getComponentTranslation('header') };
      if (this.isThemePreview) {
        headerValues.menu_dropdown_title = this.theme.header.options.menu_field;
        headerValues.schools_dropdown_title = this.theme.header.options.schools_field;
        headerValues.services_dropdown_title = this.theme.header.options.services_field;
      }
      return headerValues;
    },
    orgDropdownTitle() {
      return this.globals.info.organization_type === 'Municipality'
        ? this.getHeaderValues.services_dropdown_title || 'Services'
        : this.getHeaderValues.schools_dropdown_title;
    },
    hasCustomSchoolsDropdown() {
      return this.theme?.header?.options?.custom_schools_dropdown;
    },
    newMobileHeader() {
      return !!this.$store.state.featureFlags.cms_new_mobile_header_enabled;
    },
    orgMenuContent() {
      return this.homepage?.schools_menu?.menu || [];
    },
    displayStickyFooter() {
      const hasActiveStickyFooter = this.theme.sticky_footer?.options?.active && !isEmpty(this.homepage.sticky_footer);
      const currentPageHasStickyFooter =
        ['index', 'alias-index', 'alias-preview-token-id', 'preview-token-id'].includes(this.$route.name) ||
        !this.homepage.sticky_footer?.show_only_on_homepage;
      return hasActiveStickyFooter && currentPageHasStickyFooter;
    },
    globalContainers() {
      const containersWithIndex = this.theme.containers.map((container, idx) => ({
        ...container,
        containerIndex: idx,
      }));
      return containersWithIndex.filter((container) => !!container.global);
    },
    hasGlobalContainers() {
      return !isEmpty(this.globalContainers);
    },
    hideAlerts() {
      return {
        banner: this.mobileHeaderStatus && this.menuStatus,
        modal: this.showingBrowserNotice,
      };
    },
  },
  watch: {
    isSearchOpen: {
      handler(val) {
        if (!val) {
          this.$store.commit('setSearchOpen', false);
          return;
        }

        this.$store.commit('setSearchOpen', val);
        if (val || !this.wasSearchModalOpenInLastUrl) {
          return;
        }
        this.setFocusInSearchIcon();
      },
      immediate: true,
    },
  },
  async mounted() {
    this.isClient = true;
    let isIE = false;
    const ie = window.navigator.userAgent.indexOf('MSIE ');
    const browserNotice = window.sessionStorage.getItem('UNSUPPORTED_BROWSER_NOTICE');

    if (ie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      isIE = true;
    }

    if (isIE && !browserNotice) {
      this.showingBrowserNotice = true;
    }
    this.fireLoadEvents();
  },
  beforeUpdate() {
    if (typeof document !== 'undefined' && document.URL.includes('openSearch')) {
      this.wasSearchModalOpenInLastUrl = true;
    }
  },
  methods: {
    toggleMenu(status) {
      this.$store.commit('toggleMenu', status);
    },
    async languageChange(lang) {
      const language = this.languages.find(({ code }) => code === lang.code);
      if (language.code !== this.locale) {
        await this.$store.dispatch('translation/setLangCookies', { ...language, shouldReload: true });
      }
    },
    focusTopElement() {
      this.$nextTick(() => {
        this.$refs.skipToContent.focus();
      });
    },
    closeBrowserModal() {
      this.showingBrowserNotice = false;
      window.sessionStorage.setItem('UNSUPPORTED_BROWSER_NOTICE', true);
    },
    toggleMobileMenu(status) {
      this.toggleMenu(status);
      this.mobileHeaderStatus = status;
    },
    toggleSearch() {
      this.$store.commit('setSearchOpen', !this.searchOpen);
      return navigateTo({ path: this.$route.currentPath, query: { ...this.$route.query, openSearch: 'true' } });
    },
    setFocusInSearchIcon() {
      if (typeof document === 'undefined') {
        return;
      }
      const searchButton = document.getElementsByClassName('focus-search-icon');
      if (searchButton[0]) {
        searchButton[0].focus();
      }
    },
    fireLoadEvents() {
      // see https://apptegy.atlassian.net/browse/CMS2-461
      if (this.disableLoadEvents) return;

      const isDomReady = () => document.readyState === 'complete';
      const interval = setInterval(() => {
        if (isDomReady()) {
          clearInterval(interval); // Stop checking once the document is ready
          const event = new CustomEvent('appLoaded');
          window.dispatchEvent(event);
          document.dispatchEvent(event);
        }
      }, 50);
    },
  },
};
</script>

<style lang="scss">
@import '../components/global/scss/mixins/breakpoints';
html,
body,
#app {
  margin: 0;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}
h1 {
  font-weight: 700;
  &.bold {
    font-weight: 900;
  }
}
h2,
h3,
h4,
h5 {
  font-weight: 700;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

/*Text align for News articles*/
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

button.button-action {
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  padding: 0;
  .button-action-content {
    display: block;
  }
  &,
  > .button-action-content {
    outline: none;
    text-align: left;
    white-space: nowrap;
  }
  &:focus-visible > .button-action-content {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }
}
.noscroll {
  overflow: hidden;
}
.page-container {
  width: 90%;
  margin: 0 auto;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 70px;
  &:last-child {
    margin-bottom: 80px;
  }
  .filter {
    width: auto;
    float: right;
    select {
      line-height: 22px;
      margin-top: 10px;
      margin-bottom: 20px;
      width: 250px;
      padding: 5px 8px;
      padding-right: 33px;
      border: none;
      appearance: none;
      background: url('../assets/small_arrow_down.png') no-repeat 100% 50%;
      font-size: 16px;
      text-transform: uppercase;
      color: #717171;
    }
  }
  .page-container-item {
    border-bottom: 1px solid #ddd;
    padding: 18px 0;
    margin: 0 auto;
    overflow: hidden;
    clear: both;
  }
}
.search-enter-from,
.search-leave-to {
  transform: scale(0.9);
  opacity: 0;
}
.search-enter-to,
.search-leave-from {
  transform: scale(1);
  opacity: 1;
}
th {
  font-weight: 700;
}
blockquote,
strong {
  font-weight: 700;
}
.skip-to-content {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  &:focus {
    width: auto;
    height: auto;
    background-color: #f1f1f1;
    border-radius: 3px;
    clip: auto;
    clip-path: none;
    color: #21759b;
    font-size: 14px;
    font-weight: bold;
    left: 5px;
    padding: 15px 23px 14px;
    top: 5px;
    text-align: center;
    z-index: 20;
  }
}

// Allows Google recaptcha privacy info to appear on top of modal
.grecaptcha-badge {
  z-index: 100;
}
</style>
