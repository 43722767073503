import {
  indexVuexStore,
  eventsindexVuexStore,
  staffindexVuexStore,
  translationindexVuexStore 
} from "#imports"
const VuexStore = {
  ...indexVuexStore,
  modules: {
      events: {
        ...eventsindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      staff: {
        ...staffindexVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      translation: {
        ...translationindexVuexStore,
        namespaced: true,
        modules: {},
      }
    }, 
  }
export default VuexStore