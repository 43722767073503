<template>
  <div data-testid="live-feed">
    <LoadingIndicator v-if="loading" />
    <cms-live-feed
      v-else
      :theme="themeSettings"
      :content="generateDynamicTimeAgoValues(content)"
      :livefeed-config="config"
      :component-id="componentId"
      :fallback-image="fallbackImage"
      :path-prefix="pathPrefix"
      :logo="watermarkLogo"
      @savePrevFocusedElem="savePrevFocusedElem($event)"
      @toggleLightbox="toggleLightbox($event)"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import LoadingIndicator from '../../global/LoadingIndicator';
import HomepageFetchHelper from '../../../helpers/homepage-fetcher.js';
import { getTimeAgoStringFromUTC } from '../../../helpers/time-ago-in-words.js';

export default {
  name: 'ViewLiveFeed',
  components: {
    LoadingIndicator,
  },
  props: {
    containerIdx: {
      type: Number,
      default: null,
    },
    componentIdx: {
      type: Number,
      default: null,
    },
    containerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      content: [],
      config: {},
    };
  },
  computed: {
    ...mapState([
      'homepage',
      'theme',
      'pathPrefix',
      'isThemePreview',
      'featureFlags',
      'globals',
      'settings',
      'staticFilesConfig',
      'mediaPreviewContent',
    ]),
    ...mapGetters(['getLiveFeedApi', 'getS3UrlForMedia', 'getGlobalThemeSettings']),
    ...mapGetters('translation', ['getComponentTranslation']),
    defaultData() {
      return this.mediaPreviewContent?.liveFeedPreviewContent || [];
    },
    themeSettings() {
      return {
        ...this.theme.containers[this.containerIdx].components[this.componentIdx].options,
        translations: this.getComponentTranslation('live_feed'),
        globalThemeSettings: this.getGlobalThemeSettings,
      };
    },
    componentId() {
      if (this.isThemePreview) {
        return 'preview_id';
      }

      return `${this.containerId}_${this.componentIdx}`;
    },
    useS3ForMedia() {
      return this.featureFlags.cms_stored_data_live_feed_enabled;
    },
    watermarkLogo() {
      return this.globals.watermark?.url || this.globals.logo.url;
    },
    fallbackImage() {
      const liveFeedDefaultFallbackImage =
        this.globals?.fallback_images?.find((item) => item.feature === 'live_feed') || {};
      const settings = this.settings || {};
      const liveFeedFallbackImage = settings.liveFeedFallbackImage || {};
      return Object.keys(liveFeedFallbackImage).length === 0 ? liveFeedDefaultFallbackImage : liveFeedFallbackImage;
    },
  },
  mounted() {
    this.config = this.homepage.live_feed || {};
    this.getContent();
  },
  methods: {
    async getContent() {
      if (this.isThemePreview) {
        this.setupThemePreview();
        return;
      }

      if (this.useS3ForMedia) {
        this.content = await this.fetchContentFromS3AndValidate();
      } else {
        this.content = await this.fetchContentFromThrillshare();
      }
      this.loading = false;
    },
    setupThemePreview() {
      this.content = this.defaultData.map((lf) => ({
        ...lf,
        avatar: lf.avatar || this.globals.logo.url,
      }));
      this.loading = false;
    },
    async fetchContentFromS3AndValidate() {
      try {
        const s3UrlForContent = this.getS3UrlForMedia('live_feed');
        const contentResponse = await this.$staticFetch.get(s3UrlForContent);

        const lastS3FileUpdate = contentResponse.data?.meta?.last_static_update;
        const lastMediaUpdate =
          this.staticFilesConfig?.last_static_update?.live_feeds ||
          this.staticFilesConfig?.homepage?.last_static_update?.live_feeds;

        const isStaticDataFresh = HomepageFetchHelper.validateLastStaticUpdate(lastS3FileUpdate, lastMediaUpdate);
        const liveFeedPosts = contentResponse?.data?.live_feeds;
        if (!isStaticDataFresh || !liveFeedPosts) {
          return await this.fetchContentFromThrillshare();
        }

        return liveFeedPosts;
      } catch (e) {
        console.error(e);
        return await this.fetchContentFromThrillshare();
      }
    },
    async fetchContentFromThrillshare() {
      try {
        const { url, params } = this.getLiveFeedApi(this.$axios.defaults.baseURL);
        const contentResponse = await this.$axios.get(url, { params, cache: { ignoreCache: false } });

        return contentResponse?.data?.live_feeds || [];
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    generateDynamicTimeAgoValues(liveFeeds) {
      if (this.isThemePreview) {
        return liveFeeds;
      }
      return liveFeeds?.map((lf) => {
        return {
          ...lf,
          time_ago: getTimeAgoStringFromUTC(lf.time, this.$t),
        };
      });
    },
    savePrevFocusedElem(target) {
      this.$store.commit('setPrevFocusedElem', target);
    },
    toggleLightbox(imgObj) {
      this.$store.commit('setPrevFocusedElem', imgObj.target);
      this.$store.commit('setLightBoxCurrentIndex', imgObj.idx || 0);
      this.$store.commit('openLightbox');
      this.$store.commit('setLightboxImages', imgObj.images);
    },
  },
};
</script>
