<template>
  <Default>
    <div class="error-page-layout">
      <div class="error-page">
        <div class="content">
          <span
            id="status-code"
            data-testid="error-status-code"
          >
            {{ `Error: ${error.statusCode}` }}
          </span>
          <h1>
            {{ dataByStatusCode.title }}
          </h1>
          <client-only>
            <p v-html="error.message || dataByStatusCode.message" />
          </client-only>
          <div id="go-Home-wrapper">
            <a
              :href="homePath"
              :style="{ backgroundColor: getButtonColor }"
            >
              {{ $t('pages.shared.returnHome') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </Default>
</template>

<script>
import Default from './layouts/default.vue';
import get from 'lodash/get';
import { mapGetters } from 'vuex';

const DATA_BY_STATUS_CODE = {
  404: {
    title: 'Page not found',
    message: `
    <p>We can't seem to find the page you're looking for.</p>
    <p>It might have been removed or replaced.</p>`,
  },
};

export default {
  components: {
    Default,
  },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    useHead({
      title: 'Page Not Found',
    });
  },
  computed: {
    ...mapGetters(['getPathPrefix', 'getGlobals']),
    getButtonColor() {
      const info = this.getGlobals.info;
      const page_builder_theme_color = get(info, 'page_builder_theme_color.hex', '');
      const cms_heading_color = get(info, 'cms_heading_color.hex', '');
      return page_builder_theme_color || cms_heading_color || '#000000';
    },
    dataByStatusCode() {
      return (
        DATA_BY_STATUS_CODE[this.error.statusCode] || {
          title: 'Error',
          message: 'Something went wrong',
        }
      );
    },
    homePath() {
      return this.getPathPrefix || '/';
    },
  },
  mounted() {
    console.error(this.error);
  },
};
</script>

<style lang="scss" scoped>
.error-page {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-size: 14px;

  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 100px;
    overflow: auto;

    @media screen and (max-width: 600px) {
      padding: 50px;
    }

    #status-code {
      color: #747474;
    }

    p {
      padding: 30px 20px 50px 0px;
    }

    h1 {
      font-size: 45px;
    }

    #go-Home-wrapper {
      a {
        margin-top: 25px;
        text-decoration: none;
        color: #ffffff;
        padding: 10px 15px;
        border-radius: 3px;
        cursor: pointer;
      }
      a:focus-visible {
        outline: 2px solid -webkit-focus-ring-color;
        outline-offset: 1px;
        box-shadow: inset 0 0 0px 2px #ffffff;
      }
    }
  }
}
</style>
